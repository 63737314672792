import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Testa Painting`}</h1>
    <p>{`Below you see two view-ports, mobile and desktop. I used a mobile first approach, utilizing images provided by my client with a slider to showcase his work. Using Yelp reviews, I created cards to showcase some of his testimonials and recommendations as it is a big part of his business.`}</p>
    <p>{`I used bxslider to showcase the images and SASS for the styles as well as a simple contact form.`}</p>
    <div className="pure-g">
  <div className="pure-u-1 pure-u-md-1-2">
    <h3>Small Viewport</h3>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Ftesta-mobile.jpg?alt=media&token=4661bc23-dc04-4828-8b71-8e0309f276d8" width="332" height="1486" alt="small viewport" />
  </div>
  <div className="pure-u-1 pure-u-md-1-2">
    <h3>Large Viewport</h3>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Ftesta-desktop.jpg?alt=media&token=c0fcbf8a-31cd-4d27-aa38-67bd9bae4176" width="auto" height="auto" alt="large viewport" />
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      